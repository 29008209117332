@charset "utf-8";
/* TOC

- Adjustments: Video Layout
- Navigation
- Search
- Masthead
- Masthead › small-only
- Masthead › medium-only
- Masthead › large-only
- Masthead › xlarge-up
- Breadcrumb
- Meta
- Jump to top
- Footer
- Subfooter
- CSS-Classes to add margin at top or bottom

*/

/* General
------------------------------------------------------------------- */

.wrap {
    width: 90%;
    margin: 0 auto;
    max-width: 1100px;
}

/* Prevent FUOC */
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

/* Adjustments: Video Layout
------------------------------------------------------------------- */

body.video,
body.video #masthead-no-image-header { background: $black; }
body.video #masthead-no-image-header { margin-bottom: 60px; }
body.video h1,
body.video h2,
body.video h3,
body.video h4,
body.video h5,
body.video h6,
body.video p,
body.video a,
body.video blockquote:before,
body.video blockquote:after,
body.video cite,
body.video cite a,
body.video cite a:visited {
  color: get-color(primary-text-light);
}



/* Navigation
------------------------------------------------------------------- */
$navigation_height: 76px;

#navigation {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
    width: 100%;
    z-index: 1;
    background-color: $indigo-700;
}

#navigation a {
  color: $white;
}

@include breakpoint(medium down) {
  #navigation.menu li {
    display: list-item;
  }
}

.top-bar {
  min-height: $navigation_height;
}

.top-bar ul a:hover {
  background-color: $indigo-300;
}


/*
  Nav-Mobile
********************** */
@include breakpoint(small only) {
  #nav-desktop {
    display: none;
  }

  .top-bar {
    min-height: auto;
  }
}

.js-off-canvas-exit {
  background: rgba(0, 0, 0, 0.5);
}

h3.title-bar-title {
  display: inline-block;
}

.title-bar {
  background-color: get-color(primary);
  height: 2.3em;
  padding: 0.2em 0;
}

.title-bar-title {
  text-transform: uppercase;
}

#menu-mobile {
  background-color: get-color(primary);
  padding: 0;
  text-transform: uppercase;
  /*font-variant: all-small-caps;*/
  height: 100%;
}

#menu-mobile > h3 {
  text-transform: uppercase;
  margin-top: 0;
}

#menu-mobile ul {
  list-style: none;
}

#menu-mobile a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.85em;
}

#menu-mobile .active > a {
  font-weight: bold;
  color: rgba(255, 255, 255, 1) !important;
}


#menu-mobile a:hover {
  color: rgba(255, 255, 255, 1);
}

#menu-mobile h3 a, #menu-mobile h3 a:hover {
  color: rgba(0, 0, 0, 1);
}

.title-bar h3 a, .title-bar h3 a:hover {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

#menu-mobile > ul > li > a {
  font-weight: bold;
}

.menu-mobile {
  padding: 0.5em 0.5em 0 0.5em;
}

.menu-mobile > a {
  font-weight: normal;
}

.menu-mobile, .menu-mobile ul {
    line-height: 2em;
    background-color: transparent;
    list-style-type: none;
}

.menu-mobile .menu {
  border: 0;
  text-align: left;
}

.menu-mobile li a {
  color: rgba(255, 255, 255, 0.7);
}

.menu-mobile li a:hover {
  color: rgba(255, 255, 255, 1);
}

.menu-mobile > li > a {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
}


/*
  Nav-Desktop
********************** */
#nav-desktop {
  background-color: get-color(primary);
  height: $navigation_height;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.9em;

  & {
    width: 98%;
  }
}

#logo-nav-desktop {
  height: $navigation_height;
  padding:3px 2px 1px 2px;
  vertical-align: middle;
}

.top-bar ul {
  background-color: get-color(primary);
}

.site-logo {
    color: white;
    line-height: $navigation_height;
}

ul.menu-desktop {
    line-height: $navigation_height;
    background-color: transparent;
}

ul.menu-desktop.dropdown ul.menu {
  border: 0;
  text-align: left;
}

ul.menu-desktop ul.submenu {
  left: auto !important;
  right: auto  !important;
}

.menu-desktop li a {
  color: white;
  white-space: nowrap;
}

.menu-desktop .submenu li > a {
  color: rgba(255, 255, 255, 1);
}

.menu-desktop .submenu li > a:hover {
  color: rgba(255, 255, 255, 1);
  // background-color: #5C6BC0;
  background-color: $pink-A200;
}

.menu-desktop > li > a {
  display: inline-block;
  line-height: $navigation_height;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.menu-desktop li a {
  letter-spacing: 0.025em;
}

.menu-desktop .submenu li.active > a {
  // background: inherit;
  font-weight: bold;
  &:hover {
    background-color: $pink-A200;
  }
}

.menu-desktop li:hover > a {
  background-color: $indigo-A200;
}

.menu-desktop li > a:hover {
  background-color: $pink-A200;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  border-top-color: $white;
}


/* Search
------------------------------------------------------------------- */

.no-js form#search {
  display: none;
}

.centered {
  @include center;
}


/* Masthead
------------------------------------------------------------------- */
.masthead {
  position: relative;
}

#masthead, #masthead-with-background-color, #masthead-with-text, #masthead-no-image-header {
  background-color: get-color(primary-dark);

  &.opacity {
    background-color: rgba(0,20,40,0.15);
  }
}

// .header-asociacion, .header-contacto {
.header-asociacion {
  #masthead, #masthead-with-background-color, #masthead-with-text, #masthead-no-image-header {
    &.opacity {
      background-color: rgba(0,20,40,0);
    }
  }
}

.header-contacto {
  #masthead, #masthead-with-background-color, #masthead-with-text, #masthead-no-image-header {
    &.opacity {
      background-color: rgba(232,234,246,0.10);
    }
  }
}


#masthead-with-text {
    text-align: center;
    font-size: rem-calc(35);
    font-family: $header-font-family;
    color: $white;
    text-transform: uppercase;
    text-shadow: 0 2px 3px rgba(0,0,0,.4);
    height: rem-calc(225);

    & .wrap {
      max-width: 70%;
      margin: 0 auto;
    }
}

#masthead-no-image-header {
    height: 175px;
}

#masthead-no-image-header #logo img {
    margin-top: 60px;
    margin-bottom: -60px;
}


/* Masthead › small-only
------------------------------------------------------------------- */

@include breakpoint(small only) {
    #logo img {
        display: none;
    }
    #masthead {
        height: 8em;
    }
    #masthead-with-pattern {
        padding: 15px 0;
    }
    #masthead-with-background-color {
        padding: 15px 0;
    }
    #masthead-with-text {
        padding: 0.8em 0;
        font-size: rem-calc(24);
        // display: none;
        height: rem-calc(175);

        .masthead-title {
          display: none;
        }
    }
    #masthead-no-image-header {
        display: none;
    }
}


/* Masthead › medium-only
------------------------------------------------------------------- */

@include breakpoint(medium only) {
   #logo img {
        // margin-top: 60px;
        // margin-bottom: -60px;
    }
    #masthead {
          height: 12.5em;
    }
    #masthead-with-pattern {
        padding: 20px 0;
    }
    #masthead-with-background-color {
        padding: 20px 0;
    }
    #masthead-with-text {
        padding: 1em 0;
    }
}


/* Masthead › large-only
------------------------------------------------------------------- */

@include breakpoint(large only) {
    #logo img {
        // margin-top: $navigation_height;
    }
    #masthead {
        height: 12.5em;
    }
    #masthead-with-pattern {
        padding: 30px 0;
    }
    #masthead-with-background-color {
        padding: 30px 0;
    }
    #masthead-with-text {
        padding: rem-calc(20) 0;
    }
}


/* Masthead › xlarge-up
------------------------------------------------------------------- */

@include breakpoint(xlarge up) {
    #logo img {
        margin-top: 110px;
    }
    #masthead {
        height: 12.5em;
    }
    #masthead-with-pattern {
        padding: 45px 0;
    }
    #masthead-with-background-color {
        padding: 45px 0;
    }
    #masthead-with-text {
        padding: rem-calc(25) 0;
    }
}


#title-image-small {
    height: 240px;
}
#title-image-large {
    height: 520px;
}
#title-image-index-small {
    height: 120px;
}
#title-image-index-large {
    height: 260px;
}



/* Breadcrumb
------------------------------------------------------------------- */

.breadcrumbs-wrapper {
  max-width: 100%;
  border-style: solid;
  border-width: 0;
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  border-radius: 0;

  background: scale-color($grey-1, $lightness: 55%);
  border-top: 1px solid scale-color($grey-1, $lightness: 45%);
  border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);

  margin: $breadcrumbs-margin;
}

.breadcrumbs {
  display: block;
  padding: .5625rem .875rem .5625rem;
  overflow: hidden;
  margin-left: 0;
  list-style: none;
  border-style: solid;
  border-width: 0;
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  border-radius: 0;

  background: scale-color($grey-1, $lightness: 55%);
  border-top: 1px solid scale-color($grey-1, $lightness: 45%);
  border-bottom: 1px solid scale-color($grey-1, $lightness: 45%);
  margin: 0;
}

.breadcrumbs>.current {
  font-weight: bold;
}


/* Meta
------------------------------------------------------------------- */

#page-meta {
  color: get-color(secondary-text);
}

#page-meta a {
    color: get-color(primary);
}

#page-meta .button {
    background: get-color(primary);
    border: 0;
}
#page-meta .button {
    color: #fff;
}
#page-meta .button:hover {
    background: get-color(primary-dark);
}
.meta-info p {
  font-size: rem-calc(13);
  color: scale-color($grey-1, $lightness: 40%);
}
  .meta-info a {
    text-decoration: underline;
    color: scale-color($grey-1, $lightness: 40%);
  }
  .meta-info a:hover {
    text-decoration: none;
    color: $secondary-color;
  }



/* Jump to top
------------------------------------------------------------------- */

#up-to-top {
    padding: 2em 0 10px 0;
}
#up-to-top a {
    font-size: 24px;
    padding: 5px;
    border-radius: 3px;
}
#up-to-top a:hover {
    background: $grey-2;
}



/* Footer
------------------------------------------------------------------- */

ul.inline-list li {
  float: left;
  padding-right: 1em;
}

#footer-content p,
#footer-content li {
    font-size: rem-calc(13);
    font-weight: 300;
}

#footer {
    padding-top: 30px;
    padding-bottom: 20px;
    background: $footer-background;
    color: $footer-color;
    }

    #footer a {
        color: $footer-link-color;
    }
    #footer h4,
    #footer h5 {
        letter-spacing: 1px;
        color: #fff;
        text-transform: uppercase;
    }



/* Subfooter
------------------------------------------------------------------- */

#subfooter {
    background: $subfooter-background;
    color: $subfooter-color;
    padding-top: 30px;
}

#subfooter-left ul.inline-list {
    float: left;
}

.credits a {
    color: $subfooter-link-color;
    border: 0;
    text-transform: uppercase;
    &:hover {
        color: #fff;
  }
}

.social-icons {
    margin-bottom: 10px !important;

// Beware of SCSS-Syntax here
  li {
    padding: 0 0 20px 0;
  }
  a {
    font-size: rem-calc(23);
    display: block;
    width: 36px;
    border-radius: 50%;
    color: get-color(primary-text);
    background: get-color(secondary-text-light);
    text-align: center;
    &:hover {
      color: get-color(primary-text-light);
      background: get-color(secondary-text);
    }
  }
}



/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.t10 { margin-top: 10px !important; }
.t15 { margin-top: 15px !important; }
.t20 { margin-top: 20px !important; }
.t30 { margin-top: 30px !important; }
.t50 { margin-top: 50px !important; }
.t60 { margin-top: 60px !important; }
.t70 { margin-top: 70px !important; }
.t80 { margin-top: 80px !important; }
.t90 { margin-top: 90px !important; }

.b15 { margin-bottom: 15px !important; }
.b20 { margin-bottom: 20px !important; }
.b30 { margin-bottom: 30px !important; }
.b60 { margin-bottom: 60px !important; }

.l15 { margin-left: 15px !important; }
.r15 { margin-right: 15px !important; }

.pl20 { padding-left: 20px !important; }
.pr5  { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr20 { padding-right: 20px !important; }




/* **********************
   HERO
********************** */
.hero h1 {
  font-weight: 900;
  font-size: 3.5em;
  text-transform: uppercase;
  padding-bottom: 0em;
}

.hero h2 {
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.hero {
  color: white;
  text-shadow:
    0 0 10px rgba(0,0,0,0.01),
    0 0 5px rgba(0,0,0,0.025),
    0 1px 1px rgba(0,0,0,0.5);
  font-weight: bold;

  background-position: 25% 25%, center;
  background: center center no-repeat fixed rgba(0,0,0, 1);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @include breakpoint(small only) {
    // background-image: url("/images/portada.small.jpg");
    background-image: url("/images/granos.800.jpg");
  }
  @include breakpoint(medium only) {
    // background-image: url("/images/portada.medium.jpg");
    background-image: url("/images/granos.800.jpg");
  }
  @include breakpoint(large) {
    background-image: url("/images/portada.large.jpg");
    // background-image: url("/images/portada.800.jpg");
  }
}

.opacity {
  background-color: rgba(63,81,81,0.2);
}

.hero .opacity {
  padding: 3.5em 0;
  min-height: 20em;
}


@include breakpoint(small only) {
  .hero .opacity {
    padding: 1em 0;
    min-height: 10em;
  }
}

.hero .wrap {
  max-width: 70%;
  margin: 0 auto;
}

.hero h1,
.hero p {
  color: rgba(255,255,255, 1);
}

@include breakpoint(small only) {
  .hero {
    h1, h2, h3, p {
      margin-top: 0.15em;
      margin-bottom: 0.15em;
    }
  }
}


.hero p {
  font-style: italic;
}

@include breakpoint(small only) {
  .hero {
    font-size: 90%;
  }
  .hero h1 {
    font-size: 2.5em;
  }
}

@media (max-width: rem-calc(875)) {
  .hero h1,
  .hero p {
    width: 100%;
  }
}

/* **********************
   MAIN
********************** */
section.main {
  padding: 2em 0 0 0;
}

.main h3 {
  font-weight: 900;
}

#home-intro ul {
  list-style-type: none;
}
#home-intro ul a {
  font-weight: bold;
}

#home-intro ul a:after {
  content: "\00A0\00A0\25B8";
}

a.continue-reading:after{
  content: "\00A0\00A0\25B8";
  display: inline-block;
}

body.frontpage article {
  margin-bottom: 2em;
}

#home-news .entry-meta {
  display: none;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.4em;
}


#home-blog-posts {
  background-color: #E8EAF6; /* Indigo50 */
  padding-top: 0.5em;
}


#home-blog-posts h2, #home-news h2 {
  text-align: left;
  font-size: 1em;
  font-weight: 600;
  margin: 0 auto 1em auto;
  width: 90%;
}


#home-blog-posts h2 span, #home-news h2 span {
  font-size: 1.66em;
}

.main button, .main .button {
  background-color: #303F9F;
}

.main button:hover, .main .button:hover {
  background-color: #1A237E;
}

button.radius, .button.radius {
    border-radius: 2em;
    font-weight: bold;
}


body.frontpage .main hr {
  visibility: hidden;
}

body.frontpage .main h3 {
  margin-top: 0;
}


.entry-meta {
  font-size: 0.9em;
}

/* **********************
  REVEAL
********************** */
#contact-form-error {
  background-color: get-color(warning);
  color: get-color(primary-text-light);
}

#contact-form-error button {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  line-height: 1em;
  color: get-color(black);
  text-align: center;
  background: get-color(white);

  &:hover {
    color: primary-text-light;
  }
}

#contact-form-error a {
  color: get-color(primary-text-light);

  &:hover {
    color: get-color(secondary-text-light);
  }
}

/* **********************
   FOOTER
********************** */
footer {
    background-color: #283593;
    font-size: 0.8em;
    padding: 0em 0 1em 0;
    color: rgba(255,255,255, 1);
}

footer .column {
    padding-bottom: 0em;
}

footer h4 {
    color: rgba(255,255,255, 1);
    font-size: 1.3em;
    margin-top: 1.5em;
}

footer hr {
    border-bottom-color: rgba(255,255,255,0.3);
    margin: 0.8em 0;
}

footer a {
  color: rgba(255,255,255,1);

  &:hover, &:focus {
    color: inherit;
    text-decoration: underline;
  }
}

footer .social-icons {
  & a:hover, & a:focus {
    text-decoration: none;
  }
}

footer a span, footer span {
    color: rgba(255,255,255,0.7);
}

footer ul {
  list-style-type: none;
  margin-left: 1.5em;
  margin-bottom: 0;
}

footer div.contact-details {
  margin-left: 1.5em;
}

footer h4 {
  margin-left: 1.1em;
  font-variant-caps: all-petite-caps;
}

footer ul a, footer ul a:hover {
  display: inline-block;
}

/* **********************
   INDIVIDUAL PAGES
********************** */
.logo-gallery {
  text-align: center;
}

.logo-gallery a, .logo-gallery a:hover {
  text-decoration: none;
  border: 0;
}

.logo-gallery .column {
  margin-bottom: 2em;
}

.logo-gallery .row .column img, img.certification-logo {
  height: 150px;
}
