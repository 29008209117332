/* TOC – Color Variables

- Colors
- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/


@import '../../vendor/foundation-sites/scss/util/util';

/* Colors
------------------------------------------------------------------- */

$black: #000;
$white: #FFF;

// Material Palette: Indigo/Pink

// Indigo
$indigo-50:    #E8EAF6;
$indigo-100:   #C5CAE9;
$indigo-200:   #9FA8DA;
$indigo-300:   #7986CB;
$indigo-400:   #5C6BC0;
$indigo-500:   #3F51B5;
$indigo-600:   #3949AB;
$indigo-700:   #303F9F;
$indigo-800:   #283593;
$indigo-900:   #1A237E;
$indigo-A100:  #8C9EFF;
$indigo-A200:  #536DFE;
$indigo-A400:  #3D5AFE;
$indigo-A700:  #304FFE;


// Pink
$pink-50:    #FCE4EC;
$pink-100:   #F8BBD0;
$pink-200:   #F48FB1;
$pink-300:   #F06292;
$pink-400:   #EC407A;
$pink-500:   #E91E63;
$pink-600:   #D81B60;
$pink-700:   #C2185B;
$pink-800:   #AD1457;
$pink-900:   #880E4F;
$pink-A100:  #FF80AB;
$pink-A200:  #FF4081;
$pink-A400:  #F50057;
$pink-A700:  #C51162;


// Grey Scale
$grey-50:  #FAFAFA;
$grey-100: #F5F5F5;
$grey-200: #EEEEEE;
$grey-300: #E0E0E0;
$grey-400: #BDBDBD;
$grey-500: #9E9E9E;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;


/* Basics
------------------------------------------------------------------- */

$body-font-color:   rgba(0, 0, 0, 0.87);
$body-background: 	$grey-50;


/* Foundation Color Variables
------------------------------------------------------------------- */

$foundation-palette: (
  primary: $indigo-500,
  primary-dark: $indigo-700,
  primary-light: $indigo-100,
  accent: $pink-A200,
  accent-light: $pink-A100,
  accent-dark: $pink-A400,

  // light background
  primary-text: rgba(0, 0, 0, 0.87),
  secondary-text: rgba(0, 0, 0, 0.54),
  disabled-text: rgba(0, 0, 0, 0.38),
  divider: rgba(0, 0, 0, 0.12),

  // dark background
  primary-text-light: rgba(255, 255, 255, 1),
  secondary-text-light: rgba(255, 255, 255, 0.70),
  disabled-text-light: rgba(255, 255, 255, 0.50),
  divider-light: rgba(255, 255, 255, 0.12),

  // grey
  grey: $grey-500,
  grey-light: $grey-100,
  grey-dark: $grey-700,

  black: $black,
  white: $white,

  // Feeling Responsive
  // secondary: $pink-A200,
  secondary: $grey-500,
  // alert: $pink-700,
  alert: $pink-A200,
  // success: $indigo-700,
  success: $grey-500,
  // warning: $pink-300,
  warning: $pink-400,
  // info: $indigo-500,
  info: $grey-800,
);
// defines only: primary, secondary, success, warning, alert
@include add-foundation-colors;


/* Old variables used by Feeling Responsive theme
------------------------------------------------------------------- */

$ci-1: $grey-800;   // dark turquoise
$ci-2: $indigo-300; // turquoise
$ci-3: $pink-200;   // yellow
$ci-4: $pink-400;   // orange
$ci-5: $pink-500;   // red
$ci-6: $grey-500;   // green

$grey-1: $grey-300; // 224
$grey-2: $grey-300; // 224
$grey-3: $grey-400; // 189
$grey-4: $grey-400; // 189
$grey-5: $grey-500; // 158
$grey-6: $grey-500; // 158
$grey-7: $grey-500; // 158
$grey-8: $grey-600; // 117
$grey-9: $grey-700; // 97
$grey-10: $grey-800; // 66
$grey-11: $grey-800; // 66
$grey-12: $grey-800; // 66
$grey-13: $grey-900; // 33
$grey-14: $grey-900; // 33
$grey-15: $grey-900; // 33
$grey-16: $grey-900; // 33

/* Topbar-Navigation
------------------------------------------------------------------- */

$topbar-background: $body-background;
// $topbar-dropdown-toggle-color: $ci-1;

// $topbar-link-color: #000;
// $topbar-link-color-hover: #000;
// $topbar-link-color-active: #000;
// $topbar-link-color-active-hover: #000;

// $topbar-dropdown-label-color: $ci-2;
// $topbar-dropdown-link-background-hover: $ci-6;

// $topbar-link-background-active: $ci-6; // Active Navigation Link
// $topbar-link-background-hover: $ci-6;
// $topbar-link-background-active-hover: $ci-2;


$topbar-submenu-background: $ci-6; // Background Mobile Navigation
$topbar-submenu-background: $pink-A200; // Background Mobile Navigation
$topbar-submenu-background: $indigo-A200; // Background Mobile Navigation
// $topbar-dropdown-link-color: #000;
// $topbar-dropdown-link-background: $ci-2;

// $topbar-menu-link-color-toggled: $ci-1;
// $topbar-menu-icon-color-toggled: $ci-1;
// $topbar-menu-link-color: #000;
// $topbar-menu-icon-color: #000;
// $topbar-menu-link-color-toggled: $ci-6;
// $topbar-menu-icon-color-toggled: $ci-6;

/* Menu
------------------------------------------------------------------- */
// $menu-item-color-active: $ci-1;
$menu-item-background-active: $indigo-700;
// $menu-item-color-active: yellow;
// $menu-item-background-active: red;


/* Footer
------------------------------------------------------------------- */

$footer-background: $grey-8;
$footer-color: #fff;
$footer-link-color: $ci-6;


$subfooter-background: $grey-13;
$subfooter-color: $grey-8;
$subfooter-link-color: $grey-8;



/* Code
------------------------------------------------------------------- */

$code-background-color: scale-color(map-get($foundation-palette, secondary), $lightness: 70%);

$highlight-background: #ffffff;
$highlight-comment: #999988;
$highlight-error: #a61717;
$highlight-comment-special: #999999;
$highlight-deleted: #000000;
$highlight-error-2: #aa0000;
$highlight-literal-string: #d14;
$highlight-literal-number: #009999;
$highlight-name-attribut: #008080;
$highlight-error-background: #e3d2d2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: #445588;
$highlight-name-builtin: #0086B3;
$highlight-name-class: #445588;
$highlight-name-entity: #800080;
$highlight-name-exception: #990000;
$highlight-name-function: #990000;
$highlight-name-namespace: #555555;
$highlight-name-tag: #000080;
$highlight-text-whitespace: #bbbbbb;
$highlight-literal-string-regex: #009926;
$highlight-literal-string-symbol: #990073;
